// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';
@import '~flatpickr/dist/themes/dark.css';
//@import "src/_metronic/assets/sass/core/vendors/plugins/_flatpickr.scss";
#root {
  display: contents;
}
